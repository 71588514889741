<template>
  <div class="create-property-from-lead">
    <div class="container fluid">
      <div class="d-flex align-items-center py-3">
        <fd-button class="circle mr-2" @click="toLeadDetail"
          ><i class="fas fa-chevron-left"></i
        ></fd-button>
        <h1>Create Property</h1>
      </div>

      <PropertyForm
        :isEdit="false"
        :form.sync="property"
        :showOwnerCode="false"
        @submit="createLeadFromProperty"
      ></PropertyForm>
    </div>
  </div>
</template>

<script>
import propertyModel from "@/models/property";
import { lead as LeadAPI } from "@/api";
import { areaUnitType } from "@/enums";
import PropertyForm from "@/modules/Property/components/PropertyForm";

export default {
  components: { PropertyForm },
  mixins: [],
  props: {},
  data: function () {
    return {
      lead: {},

      property: {
        mainPhoto: [],
        gallery: [],
        latitude: "",
        longitude: "",
        tenureType: [],
        propertyFacilities: [],
        propertyAmenities: [],
        propertyCustomCategories: [],
        ownerPropertyCode: "",
        propertyOwner: {},
        areaUnitType: Object.values(areaUnitType)[0],
        landWidth: "",
        landDepth: "",
        landArea: "",
        builtUpWidth: "",
        builtUpDepth: "",
        builtUpArea: "",

        country: "",
        state: "",
        city: "",
        area: "",
        building: "",

        allowPartnership: false,
        propertyCommission: {},
        offerToPurchase: {},
        offerToRent: {},
        propertyTags: [],
        hashtag: "",
        remark: "",

        // Auction
        auctionTime: "",
        auctionDate: "",
        auctionVenue: "",
        auctionTypeId: "",
        assignee: "",
        auctioneer: "",
        lawyer: "",
        assignor: "",
        deposit: "",
        proclamationOfSale: []
      },
      propertyStatus: ""
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        await this.getLead();
        this.checkPermission();

        this.isDataLoaded = true;
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },
    toLeadDetail() {
      this.$router.push({
        name: "LeadDetail",
        params: { id: this.$route.params.id }
      });
    },
    portLeadDataToProperty() {
      // Property
      this.property.purpose = this.lead.purpose;
      this.property.propertyTypeId = this.lead.propertyType.id;
      this.property.propertyCategory = this.lead.propertyCategory;
      // Owner
      this.property.propertyOwner.name = this.lead.name;
      this.property.propertyOwner.contact = this.lead.contact;
    },
    validateDepositRates(value1, value2) {
      if (Number(value1) + Number(value2) > 10) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "OTP Total Deposit Limit",
          text:
            "Sum of first deposit and second deposit rate should be more than 10%"
        });
        return false;
      }
      return true;
    },
    // ============================== API RELATED ==============================
    async getLead() {
      try {
        this.$store.commit("setIsLoading", true);
        let data = await LeadAPI.getLead(this.$route.params.id);
        this.lead = data;
        this.portLeadDataToProperty();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch lead data. Please try again later."
        });
      }
    },
    async createLeadFromProperty() {
      this.$store.commit("setIsLoading", true);
      try {
        await LeadAPI.createPropertyFromLead(
          this.$route.params.id,
          propertyModel.postPayload(this.property)
        );
        this.$store.commit("setIsLoading", false);

        this.$notify({
          group: "alert",
          type: "success",
          title: "Create Property",
          text: "Property has been created with lead."
        });

        this.$router.push({ name: "ManageProperty" });
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to create property from lead. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
